// imports the React Javascript Library
// import React from "react";
//Card
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

//Tabs
import { withStyles } from "@material-ui/core/styles";

import React, { useState } from "react";

const AvatarUpdate = (props) => {
  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
    // props.setInitialValues({
    //   ...props.initialValues,
    //   User_Profile_Image: URL.createObjectURL(e.target.files[0]),
    // });
  };
 
  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image.raw);

    await fetch("YOUR_URL", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    });
  };

  return (
    <div>
      <label htmlFor="upload-button">
        {image.preview ? (
          <>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "75px",
              }}
            >
              <Button component="span">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "64px",
                  }}
                >
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{
                      color: "#03A430",
                      fontSize: "4rem",
                      // marginTop: "40px",
                    }}
                  ></FiberManualRecordIcon>
                  <EditIcon
                    style={{
                      marginLeft: "-51%",
                      color: "#ffffff",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                  </EditIcon>
                </Box>
                <Avatar
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minHeight: "100%",
                    minWidth: "100%",
                    height: "100%",
                  }}
                  alt="User porfile"
                  src={image.preview}
                  // src={require("../../assets/img/patient.png")}
                  // src={require("../../assets/img/patient.png")}
                ></Avatar>
                {/* <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={this.handleUploadClick}
                  ></input> */}
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <span className="fa-stack fa-2x mt-3 mb-2">
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-store fa-stack-1x fa-inverse" />
            </span>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "75px",
              }}
            >
              <Button component="span">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "64px",
                  }}
                >
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{
                      color: "#03A430",
                      fontSize: "4rem",
                      // marginTop: "40px",
                    }}
                  ></FiberManualRecordIcon>
                  <EditIcon
                    style={{
                      marginLeft: "-51%",
                      color: "#ffffff",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                  </EditIcon>
                </Box>
                <Avatar
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minHeight: "100%",
                    minWidth: "100%",
                    height: "100%",
                  }}
                  alt="User porfile"
                  src={require("../../assets/img/patient.png")}
                  // src={require("../../assets/img/patient.png")}
                  // src={require("../../assets/img/patient.png")}
                ></Avatar>
                {/* <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={this.handleUploadClick}
                  ></input> */}
              </Button>
            </Grid>

            {/* <h5 className="text-center">Upload your photo</h5> */}
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <br />
      {/* <button onClick={handleUpload}>Upload</button> */}
    </div>
  );
};

AvatarUpdate.propTypes = {
  handleChange: PropTypes.func,

};

export default AvatarUpdate;
